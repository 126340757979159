<template>
  <div class="apply-organization-container">
    <div class="form-content">
      <div class="head-nav fs-14">
        <label class="fc-1 back-title" @click="back">返回上一页</label>
        <label class="fc-9">加入我们 /</label>
        <label class="fc-6">申请机构伙伴</label>
      </div>
      <div class="title fs-24 fc-1">机构伙伴申请表</div>
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="name">
          <label for="name">
            机构全称
            <span>*</span>
          </label>
          <el-input v-model="form.name" maxlength="100" width="200"></el-input>
        </el-form-item>
        <el-form-item>
          <label for="name">
            所属审核机构
            <span>*</span>
          </label>
          <div>
            <el-select v-model="form.audit_user_id" placeholder="请选择">
              <el-option
                v-for="item in organizationsList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item>
          <label for="name">
            所属省市
            <span>*</span>
          </label>
          <div>
            <el-cascader
              size="large"
              :options="regionData"
              v-model="form.area_options"
              @change="changeArea"
            ></el-cascader>
          </div>
        </el-form-item>
        <el-form-item prop="level">
          <label>
            机构评估等级
            <span>*</span>
          </label>
          <el-input v-model="form.level" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item prop="manager_name">
          <label>
            机构法人姓名
            <span>*</span>
          </label>
          <el-input v-model="form.manager_name" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item prop="manager_phone">
          <label>
            机构法人联系电话
            <span>*</span>
          </label>
          <el-input v-model="form.manager_phone" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item prop="applier_name">
          <label>
            申请联系人姓名
            <span>*</span>
          </label>
          <el-input v-model="form.applier_name" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item prop="applier_phone">
          <label>
            申请联系人联系电话
            <span>*</span>
          </label>
          <el-input v-model="form.applier_phone" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item prop="applier_email">
          <label>
            申请联系人邮箱
            <span>*</span>
          </label>
          <el-input v-model="form.applier_email" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item prop="employee_count">
          <label>
            机构目前全职员工人员数
            <span>*</span>
          </label>
          <el-input type="number" v-model="form.employee_count" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item prop="volunteer_count">
          <label>
            机构目前志愿者人数
            <span>*</span>
          </label>
          <el-input type="number" v-model="form.volunteer_count" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item prop="description">
          <label>
            机构简介
            <span>*</span>
          </label>
          <el-input v-model="form.description" maxlength="1000" type="textarea" :rows="2"></el-input>
        </el-form-item>
        <el-form-item prop="area">
          <label>
            申请义仓实施区域
            <span>*</span>
          </label>
          <el-input v-model="form.area" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item prop="community_count">
          <label>
            预计覆盖社区数
            <span>*</span>
          </label>
          <el-input type="number" v-model="form.community_count" maxlength="100"></el-input>
        </el-form-item>

        <el-form-item prop="service_member_count">
          <label>
            预计覆盖服务人数
            <span>*</span>
          </label>
          <el-input type="number" v-model="form.service_member_count" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item prop="join_reason">
          <label>
            请简要阐述您加入义仓发展网络的理由：（200字）
            <span>*</span>
          </label>
          <el-input v-model="form.join_reason" maxlength="200" type="textarea" :rows="2"></el-input>
        </el-form-item>
        <el-form-item prop="social_issues">
          <label>
            机构关注的社会议题（200字）
            <span>*</span>
          </label>
          <el-input v-model="form.social_issues" maxlength="200" type="textarea" :rows="2"></el-input>
        </el-form-item>
        <el-form-item>
          <label>
            上传登记证书（营业执照）扫描件
            <span>*</span>
          </label>
          <img-uploader
            :imageUrl="form.business_license"
            :isLittleImg="true"
            @changeImg="uploadCoverImgSuccess"
          ></img-uploader>
        </el-form-item>
        <el-form-item>
          <label>
            上传机构Logo图
            <span>*</span>
          </label>
          <img-uploader :imageUrl="form.logo" :isLittleImg="true" @changeImg="uploadLogo"></img-uploader>
        </el-form-item>
        <el-form-item>
          <label>
            您是从什么渠道知道义仓的？（选择，多选）
            <span>*</span>
          </label>
          <el-checkbox-group v-model="form.known_reason">
            <el-checkbox
              v-for="item in knownReasonList"
              name="known_reason"
              :key="item.categoryId"
              :label="item.categoryId"
            >{{item.categoryName}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <div class="btns">
          <div class="btn fs-12" @click="onSubmit('form')">确认保存</div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { API, KNOW_REASON_LIST } from "@/const/const";
import imgUploader from "@/components/public/imgUploader.vue";
import regionData from "@/assets/data/regionData.json";

export default {
  name: "applyOrganization",
  data() {
    return {
      regionData: [], // 省市数据
      organizationsList: [], // 审核机构列表
      id: null, // 如果有id表示已经申请过来，此时需要重新申请，申请接口走update方法
      form: {
        name: "",
        audit_user_id: "",
        area_options: [],
        province: "",
        city: "",
        level: "",
        manager_name: "",
        manager_phone: "",
        applier_name: "",
        applier_phone: "",
        applier_email: "",
        employee_count: "",
        volunteer_count: "",
        description: "",
        area: "",
        community_count: "",
        service_member_count: "",
        join_reason: "",
        social_issues: "",
        business_license: "",
        logo: "",
        known_reason: []
      },
      knownReasonList: KNOW_REASON_LIST,
      rules: {
        name: [{ required: true, message: "请输入机构全称", trigger: "blur" }],
        level: [
          { required: true, message: "请输入机构评估等级", trigger: "blur" }
        ],
        manager_name: [
          { required: true, message: "请输入机构法人姓名", trigger: "blur" }
        ],
        manager_phone: [
          { required: true, message: "请输入机构法人联系电话", trigger: "blur" }
        ],
        applier_name: [
          { required: true, message: "请输入申请联系人姓名", trigger: "blur" }
        ],
        applier_phone: [
          {
            required: true,
            message: "请输入申请联系人联系电话",
            trigger: "blur"
          }
        ],
        applier_email: [
          { required: true, message: "请输入申请联系人邮箱", trigger: "blur" }
        ],
        employee_count: [
          {
            required: true,
            message: "请输入机构目前全职员工人员数",
            trigger: "blur"
          }
        ],
        volunteer_count: [
          {
            required: true,
            message: "请输入机构目前志愿者人数",
            trigger: "blur"
          }
        ],
        description: [
          { required: true, message: "请输入机构简介", trigger: "blur" }
        ],
        area: [
          { required: true, message: "请输入申请义仓实施区域", trigger: "blur" }
        ],
        community_count: [
          { required: true, message: "请输入预计覆盖社区数", trigger: "blur" }
        ],
        service_member_count: [
          { required: true, message: "请输入预计覆盖服务人数", trigger: "blur" }
        ],
        join_reason: [
          {
            required: true,
            message: "请简要阐述您加入义仓发展网络的理由",
            trigger: "blur"
          }
        ],
        social_issues: [
          {
            required: true,
            message: "请输入机构关注的社会议题",
            trigger: "blur"
          }
        ]
      }
    };
  },
  components: {
    imgUploader
  },
  mounted() {
    this.getOrganizationsList();
    this.getOrganizationData();
  },
  methods: {
    // 获取机构选择列表和区域列表
    getOrganizationsList() {
      request.get(API.GET_ORGANIZATIONS_NEW_LIST).then(res => {
        this.organizationsList = res.user_options;
        this.regionData = res.area_options;
      });
    },
    // 如果用户已提交申请表，请求原数据用于回填
    getOrganizationData() {
      const info = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
      if (info.organization && info.organization.id) {
        this.id = info.organization.id;
        request
          .get(API.ORGANIZATIONS + `/${info.organization.id}`)
          .then(res => {
            this.form = res;
            // 地区选择器回填数据重构
            this.form.area_options = [res.province, res.city];
          });
      }
    },
    back() {
      this.$router.back();
    },
    uploadCoverImgSuccess(fileUrl) {
      this.form.business_license = fileUrl;
    },
    uploadLogo(fileUrl) {
      this.form.logo = fileUrl;
    },
    changeArea(e) {
      this.form.province = e[0];
      this.form.city = e[1];
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.form.area_options.length === 0) {
            this.$message.error("请选择所属省市");
            return;
          }
          if (!this.form.business_license) {
            this.$message.error("请上传登记证书（营业执照）扫描件");
            return;
          }
          if (!this.form.logo) {
            this.$message.error("请上传机构Logo");
            return;
          }
          if (this.form.known_reason.length === 0) {
            this.$message.error("请选择您了解义仓的渠道");
            return;
          }
          const organization = { ...this.form };
          delete organization.area_options;
          delete organization.province_name;
          delete organization.city_name;
          if (this.id) {
            this.updateOrganization(organization);
          } else {
            this.postOrganization(organization);
          }
        } else {
          this.$message.error("请填写完整表单");
        }
      });
    },
    postOrganization(organization) {
      request.post(API.ORGANIZATIONS, { organization }).then(res => {
        this.alertMsg();
      });
    },
    updateOrganization(organization) {
      request
        .put(API.ORGANIZATIONS + `/${this.id}`, { organization })
        .then(res => {
          this.alertMsg();
        });
    },
    alertMsg() {
      this.$alert("提交成功", "提示", {
        confirmButtonText: "确定",
        callback: action => {
          this.$router.go(-1);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.apply-organization-container {
  background-color: #eee;
  padding: 0.32rem 3rem;
  .form-content {
    background-color: #fff;
    border-radius: 0.01rem;
    padding: 0 1.6rem;
    padding-bottom: 0.56rem;
    position: relative;
    .head-nav {
      position: absolute;
      left: 0.32rem;
      top: 0.16rem;
      .back-title {
        cursor: pointer;
        padding-right: 0.2rem;
      }
    }
    .title {
      text-align: center;
      font-weight: 500;
      padding: 0.48rem 0 0.35rem;
    }
    .btns {
      justify-content: center;
      padding-top: 0.1rem;
      .btn {
        margin: 0 auto;
        width: 1.6rem;
        text-align: center;
        padding: 0.06rem 0.56rem;
        background-color: #efefef;
        border-radius: 0.02rem;
        cursor: pointer;
        background-color: var(--common-red);
        color: #fff;
      }
    }
  }
}
i {
  font-size: 0.4rem !important;
}
.el-message {
  .el-message__icon {
    font-size: 0.3rem !important;
  }
}
/deep/.el-message__icon.el-icon-error {
  font-size: 0.3rem;
}
span {
  font-size: 0.12rem;
  color: #ff3f3d;
}
</style>