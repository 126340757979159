<template>
  <el-upload
    class="img-uploader"
    :class="{'cover-img-uploader': isCoverImg, 'little-img-uploader': isLittleImg, 'avatar-img-uploader': isAvatarImg}"
    :show-file-list="false"
    :on-change="onChangeImg"
    :auto-upload="false"
  >
    <img v-if="imageUrl" :src="imageUrl" class="cover-img" />
    <i v-else class="el-icon-plus img-uploader-icon"></i>
  </el-upload>
</template>

<script>
/**
 * imageUrl: 子组件和父组件之间的绑定数据
 * changeImg: 子组件向父组件返回imageUrl
 * for-example: <img-uploader :imageUrl="form.coverImageUrl" @changeImg='changeCoverImg'></img-uploader>
 */
import request from "@/utils/request";
import { API } from "@/const/const";

export default {
  props: {
    imageUrl: {
      type: String
    },
    isCoverImg: false,
    isLittleImg: false,
    isAvatarImg: false
  },
  methods: {
    onChangeImg(file) {
      const that = this;
      const isLt2M = file.size / 1024 / 1024 < 4;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 4MB!");
        return false;
      }
      const formData = new FormData();
      formData.append("file", file.raw);
      request.post(API.POST_IMG, formData).then(res => {
        this.$emit("changeImg", res);
      });
    }
  }
};
</script>

<style>
.img-uploader {
  border: 0.01rem dashed #d9d9d9;
  border-radius: 0.6rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload {
  width: 100%;
  height: 100%;
  border: 0.01rem dashed #d9d9d9;
  border-radius: 0.6rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.img-uploader .el-upload:hover {
  border-color: #409eff;
}
.img-uploader-icon {
  font-size: 0.28rem;
  color: #8c939d;
  width: 100%;
  height: 100%;
  line-height: 1.8rem;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.cover-img {
  height: 2.4rem;
  display: block;
}

.cover-img-uploader {
  width: 3.6rem;
  height: 1.8rem;
}
.little-img-uploader {
  width: 1.8rem;
  height: 1.8rem;
}
.avatar-img-uploader {
  width: 1.6rem;
  height: 1.8rem;
}
</style>
