<template>
  <div class="index-container">
    <div class="head">
      <yicangHead class="yicang-head"></yicangHead>
      <headNav class="head-nav"></headNav>
    </div>

    <div class="body">
      <applyOrganizationPage></applyOrganizationPage>
    </div>

    <div class="footer">
      <footerBox></footerBox>
    </div>
  </div>
</template>

<script>
// head
import yicangHead from "../components/header/head";
import headNav from "../components/header/headNav";
// main
import applyOrganizationPage from "../components/container/mine/applyOrganization";
// footer
import footerBox from "../components/footers/index";

export default {
  name: "applyOrganization",
  components: {
    yicangHead,
    headNav,
    applyOrganizationPage,
    footerBox
  },
  methods: {
  }
};
</script>
